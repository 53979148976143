<template>
	<div :style="{ minHeight: minHeight + 'px' }" class="open-list">
		<van-nav-bar
			title="开通记录"
			left-arrow
			class="qjc-agent-navbar"
			@click-left="$router.back()"
		>
		</van-nav-bar>
		
		<div :style="{ minHeight: boxMinHeight + 'px' }" class="box qjc-bg-fff" ref="box">
 			<div class="title qjc-texta-l">
				<span class="qjc-fts-34">开通记录</span>
				
				<div @click="active = !active" class="check qjc-relative qjc-fr">
					<span class="qjc-fts-30">{{ typeList.filter((i) => i.value == type)[0].text }}</span>
					
					<ul :class="{ active: active }" class="check-list qjc-absolute">
						<li v-for="item in typeList" @click="checkType(item.value)" :key="item.value">{{ item.text }}</li>
					</ul>
					
					<van-icon class="arrow qjc-absolute" size="0.32rem" color="#6883FB" name="play" />
					
				</div>
				
			</div>
			
			<van-list
			  v-model="loading"
			  :finished="finished"
			  finished-text="没有更多了"
			  @load="onLoad"
			>
				<ul class="list">
					<li v-for="item in list" :key="list.id">
						<div class="info qjc-texta-l">
							<div class="type">
								{{ item.type == 2?'KOL':(item.type == 3?'机构':'未知') }}
							</div>
						
							<dl>
								<dt>{{ item.name }}</dt>
								<dd class="qjc-fts-22">{{ item.created_at }}</dd>
							</dl>
							<span>{{ item.sex == 1?'男':(item.sex == 2?'女':'未知') }}</span>
						</div>
						
						<span>{{ item.birth }}</span>
						
						<span class="qjc-texta-r">{{ item.phone }}</span>
						
					</li>
				</ul>
			</van-list>
			
		</div>
		
	</div>
</template>

<script>
	import Vue from 'vue'
	import { NavBar, List, Icon, Toast } from 'vant'
	Vue.use(NavBar)
	   .use(List)
	   .use(Icon)
	   .use(Toast);
	
	import { backAgentOpenList } from '@/js/axios'
	
	export default{
		name: 'agentAdminOpenList',
		data() {
			return {
				minHeight: window.innerHeight,// 最低高度为全屏高度
				
				boxMinHeight: 0,// 内容最小高度一屏
				
				active: false,
				
				type: 0,// 默认全部
				typeList: [
					{ text: '全部', value: 0 },
					{ text: 'KOL', value: 2 },
					{ text: '机构', value: 3 }
				],
				
				list: [],
				loading: false,
				finished: false,
				
				page: 0,
				pagesize: 10,
				pagecount: 0
				
			}
		},
		mounted() {
			this.$nextTick(() => {
				// 内容最小占满一屏
				this.boxMinHeight = this.minHeight - this.$refs.box.offsetTop - (document.querySelector('html').style
				.fontSize.replace('px', '')*0.24);
				
			})
			
		},
		methods: {
			onLoad(type) {
				backAgentOpenList({
					page: ++this.page,
					pagesize: this.pagesize,
					type: this.type
				}).then(res => {
					// console.log(res)
					if(res.code == 200){
						this.list = this.list.concat(res.data.data);
						
						this.page = res.data.pager.page;
						this.pagecount = res.data.pager.pagecount;
						
						this.loading = false;
						
						if(this.page >= this.pagecount){
							this.finished = true;
						}
					}else{
						Toast.fail(res.msg);
					}
				});
				
			},
			
			checkType(type) {
				if(type != this.type){
					this.type = type;// 切换类型
					this.page = 0;// 重置页数
					this.list = [];// 重置列表
					this.finished = false;
					this.onLoad(type);
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.open-list{
		color: #3B4549;
		background-color: #EBEDF5;
		padding-bottom: 0.24rem;
		
		.box{
			border-radius: 0.12rem;
			margin: 0.24rem 0.24rem 0;
			
			.title{
				display: flex;
				justify-content: space-between;
				align-items: center;
				height: 0.96rem;
				border-bottom: 0.01rem solid #E1E3EB;
				padding: 0 0.6rem 0 0.72rem;
				background: url(../../../../assets/images/agent_admin_openList.png) no-repeat;
				background-size: 0.34rem 0.34rem;
				background-position: 0.24rem center;
			}
			
			.check{
				.check-list{
					top: 130%;
					left: 50%;
					opacity: 0;
					border: 0.01rem solid #E1E3EB;
					border-radius: 0.08rem;
					font-size: 0.26rem;
					line-height: 0.4rem;
					background-color: #fff;
					pointer-events: none;
					transform: translateX(-50%);
					transition: all .3s;
					overflow: hidden;
					
					&.active{
						pointer-events: auto;
						opacity: 1;
					}
					&.active + .arrow{
						transform: translate(80%, -50%)rotateZ(-90deg);
					}
					
					li{
						padding: 0.12rem 0.24rem;
					}
					
				}
				
				.arrow{
					top: 50%;
					right: -0.12rem;
					transform: translate(80%, -50%)rotateZ(90deg);
					transition: all .3s;
				}
			}
			
			.list{
				padding: 0.26rem 0.36rem 0 0.24rem;
				
				li{
					display: flex;
					justify-content: space-between;
					align-items: center;
					font-size: 0.27rem;
					
					&:not(:last-child){
						margin-bottom: 0.4rem;
					}
					
					&>span{
						min-width: 1.6rem;
					}
					
					.info{
						display: flex;
						align-items: center;
						
						.type{
							display: flex;
							justify-content: center;
							align-items: center;
							width: 0.72rem;
							height: 0.72rem;
							font-size: 0.28rem;
							color: #6883FB;
							border-radius: 50%;
							background-color: #E7ECFF;
							margin-right: 0.13rem;
						}
						
						dl{
							dt{
								line-height: 0.27rem;
							}
							dd{
								color: #8C9699;
								line-height: 0.22rem;
								margin-top: 0.13rem;
							}
						}
						span{
							align-self: flex-start;
							margin-left: 0.09rem;
						}
					}
					
				}
				
			}
			
		}
		
	}
</style>
